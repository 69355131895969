<template>
  <div>
    <modal
        name="reset-password-modal"
        classes="modal py-2"
        :minWidth="200"
        :minHeight="400"
        :maxWidth="480"
        :scrollable="true"
        :reset="true"
        :adaptive="true"
        width="90%"
        height="auto"
        @closed="onCloseModal"
        >
        
      <div class="flex justify-end relative">
        <button @click="$modal.hide('reset-password-modal')" class="absolute right-0 mr-4 mt-4">
          <img src="@/assets/times-black.svg" alt="">
        </button>
      </div>

      <h2 class="text-center my-4" style="font-size: 24px">PAROLANI SIFIRLA <br> OYNAMAYA BAŞLA</h2>
      
      <div class="mx-auto px-4">
        <p class="mb-4 text-center text-danger">{{ errorMsg }}</p>
        <form class="flex flex-col items-center" @submit.prevent="resetPassword">
          <validation-observer class="flex flex-col mb-4" style="width:80%" ref="form">
            <validation-provider tag="div" name="E-Mail Adresi" rules="required|email" v-slot="{errors}">
              <input type="text" :disabled="loading" v-model="currentUsername" required name="username" class="form-special" placeholder="E-Mail Adresi">
              <div class="error-label">{{ errors[0] }}</div>
            </validation-provider>  
          </validation-observer>
      
          <button class="btn btn--primary relative mb-5" v-bind:class="{'ld-over-inverse running' : this.loading == true}" @click="resetPassword">
            Parola Sıfırla
            <div class="ld ld-ring ld-spin" v-bind:class="{'running' : this.loading == true}" v-if="loading"></div>
          </button>
        </form>
      </div>

    </modal>

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: {ValidationProvider, ValidationObserver},
  name: "reset-password-modal",
  props: {
    username: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      currentUsername: null,
      errorMsg: "",
      loading: false
    }
  },
  computed: {
    ...mapState(["player"]),
  },
  created() {
    this.currentUsername = this.username
  },
  methods: {
    ...mapActions(['login', 'register', 'createPasswordReset']),
    onCloseModal() {
      this.currentUsername = null;
    },
    resetPassword() {
      this.$refs.form.validate().then((isValid) => {
        if(!isValid) return;
        this.loading = true
        this.createPasswordReset({username: this.currentUsername}).then((response) => {
          this.loading = false
          if(response.data.code == "200.0404") {
            this.errorMsg = "Bu e-mail adresi ile bir kullanıcı bulunamadı."
            return;
          }
          this.$emit('handleReset', {
            username: this.currentUsername,
          })
        }).catch(() => {
          this.loading = false;
          this.$toast.error('Bir problem oluştu lütfen daha sonra tekrar deneyin');
        })
      })
    }
  }
}
</script>

<style scoped>

</style>