<template>
  <div class="h-full flex flex-col" v-if="productGroups">
    <div class="store-tabs flex" :style="{'left': `${fixedLeft}px`}">
      <div @click="onClickStoreTab(`section-${i+1}`)" :ref="`tab${i+1}`" :class="{'active': activeTab == `tab${i+1}`}"
        class="store-tabs__item" v-for="(productGroup, i) in filteredProductGroups" :key="`store-tab-${i}`">
        <img v-if="productGroup && productGroup.id == 1" src="@/assets/icon-coin.svg" alt="">
        <img v-if="productGroup &&  productGroup.id == 2" src="@/assets/icon-ticket.svg" alt="">
        <img v-if="productGroup && productGroup.id == 3" src="@/assets/icon-relive.svg" alt="">
        <img v-if="productGroup && productGroup.id == 4" src="@/assets/store/chest-immortal.svg" alt="">
      </div>
    </div>
    <div class="flex justify-center items-center relative w-full">
      <button @click="$modal.show('help-modal')" class="absolute left-0 ml-6">
        <img src="@/assets/icon-help.svg" alt="">
      </button>
      <h1 class="font-semibold text-center py-4 flex-shrink-0">MAĞAZA</h1>
    </div>
    <div ref="storeContent" id="store-content" class="flex flex-col items-center px-4" :class="{'overflow-scroll' : $route.name != 'store'}">
      <p class="text-center text-red-600" v-if="null != this.errorMsg">{{ this.errorMsg }}</p>
      <div :id="`section-${i+1}`" class="mb-8 w-full" v-for="(productGroup, i) in filteredProductGroups" :key="`${productGroup.id}-${productGroup.title}`">
        <h3 class="text-center">{{ productGroup.title }}</h3>
        <div class="flex justify-center items-center relative w-full" v-if="storeAdRewards['coin-packages'].length > 0 && productGroup.title == 'KOİN PAKETLERİ'">
          <div class="w-full my-4" v-for="storeAdReward in storeAdRewards['coin-packages']" :key="storeAdReward.id">
            <button class="btn-store-reward btn-store-reward--coin w-full" :class="{disabled : storeAdReward.availableAt }" :disabled="storeAdReward.availableAt" @click="!storeAdReward.availableAt && onClickRewardAdButton(storeAdReward.adPlacement.placementCode, 'coin')">
              <div class="btn-store-reward__img flex-none">
                <img src="@/assets/icon-coin-reward.svg" class="mr-2" :alt="storeAdReward.name">
                <span class="text-stroke"><small>x</small>{{storeAdReward.adPlacement.rewardInstructions.coins.value}}</span>
              </div>
              <div class="flex-grow flex flex-col items-center relative">
                <img src="@/assets/play-icon.svg" class="mr-2" style="margin: auto" :alt="storeAdReward.name">
                <h1 class="font-bold text-center">{{ storeAdReward.availableAt ? "ALINDI" : "ÜCRETSİZ!"}}</h1>
                <div class="flex items-center text-sm absolute -bottom-5" v-if="storeAdReward.availableAt">
                  <span class="mr-1">Sonraki ödül:</span>
                  <div class="font-semibold text-base">{{adRewardsTimers['coin-packages'][storeAdReward.id].text}}</div>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div class="flex justify-center items-center relative w-full" v-if="storeAdRewards['ticket-packages'].length > 0 && productGroup.title == 'BİLET PAKETLERİ'">
          <div class="w-full my-4" v-for="storeAdReward in storeAdRewards['ticket-packages']" :key="storeAdReward.id">
            <button class="btn-store-reward btn-store-reward--ticket w-full" :class="{disabled : storeAdReward.availableAt }" :disabled="storeAdReward.availableAt" @click="!storeAdReward.availableAt && onClickRewardAdButton(storeAdReward.adPlacement.placementCode, 'ticket')">
              <div class="btn-store-reward__img flex-none">
                <img src="@/assets/icon-ticket-reward.svg" class="mr-2" :alt="storeAdReward.name">
                <span class="text-stroke"><small>x</small>{{storeAdReward.adPlacement.rewardInstructions.tickets.value}}</span>
              </div>
              <div class="flex-grow flex flex-col items-center relative">
                <img src="@/assets/play-icon.svg" class="mr-2" style="margin: auto" :alt="storeAdReward.name">
                <h1 class="font-bold text-center">{{ storeAdReward.availableAt ? "ALINDI" : "ÜCRETSİZ!"}}</h1>
                <div class="flex items-center text-sm absolute -bottom-5">
                  <template v-if="storeAdReward.availableAt">
                    <span class="mr-1">Sonraki ödül:</span>
                    <div class="font-semibold text-base">{{adRewardsTimers['ticket-packages'][storeAdReward.id].text}}</div>
                  </template>
                  <template v-else>
                    {{storeAdReward.name}} KAZAN
                  </template>
                </div>
              </div>
            </button>
          </div>
        </div>
        <div class="grid gap-4 grid-cols-2 w-full">
          <div class="store-item justify-self-center	" :class="{'col-span-2': product.type == 'm'}"
               @click="beginTransaction(product)" v-for="(product) in productGroup.products"
               :key="`${product.id}-${product.sku}`">
            <div class="store-item__img" v-if="product.type == 'c'">
              <img :src="product.image" alt="title">
              <span class="text-stroke"><small>x</small>{{ product.rewardInfo.coins }}</span>
            </div>
            <div class="store-item__img" v-if="product.type == 't'">
              <img :src="product.image" alt="title">
              <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.ticket }}</span>
            </div>
            <div class="store-item__img" v-if="product.type == 'o'">
              <img src="@/assets/store/ticket-immortal.svg" alt="">
              <span class="text-stroke">
                <small>+</small>
                <template v-if="product.rewardInfo.powerups.immortality.validUntil <= 24">
                  {{product.rewardInfo.powerups.immortality.validUntil}} Saat
                </template>
                <template v-else-if="product.rewardInfo.powerups.immortality.validUntil > 24">
                  {{product.rewardInfo.powerups.immortality.validUntil / 24}} Gün
                </template>
              </span>
            </div>
            <div class="store-item__img" v-if="product.type == 'p'">
              <template v-if="product.rewardInfo.powerups.rewind > 0">
                <img :src="product.image" :alt="product.title">
                <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.rewind }}</span>
              </template>
              <template v-if="product.rewardInfo.powerups.open > 0">
                <img :src="product.image" :alt="product.title">
                <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.open }}</span>
              </template>
              <template v-if="product.rewardInfo.powerups.change > 0">
                <img :src="product.image" :alt="product.title">
                <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.change }}</span>
              </template>
              <template v-if="product.rewardInfo.powerups.hint > 0">
                <img :src="product.image" :alt="product.title">
                <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.hint }}</span>
              </template>
            </div>
            <div class="store-item__img store-item__img--chest" v-if="product.type == 'm'">
              <template v-if="product.rewardInfo.powerups.immortality.isActive">
                <img class="mb-2" :src="product.image" :alt="product.title">
                <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.rewind > 0">
                  <img src="@/assets/store/rewind.svg" class="mr-2" alt="">
                  <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.rewind }}</span>
                </div>
                <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.open > 0">
                  <img src="@/assets/store/open.svg" class="mr-2" alt="">
                  <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.open }}</span>
                </div>
                <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.change > 0">
                  <img src="@/assets/store/skip.svg" class="mr-2" alt="">
                  <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.change }}</span>
                </div>
                <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.hint > 0">
                  <img src="@/assets/store/hint.svg" class="mr-2" alt="">
                  <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.hint }}</span>
                </div>
                <div class="store-item__chest-item flex items-center">
                  <img src="@/assets/store/ticket-immortal.svg" class="mr-2" alt="">
                  <span class="text-stroke">
                    <small>+</small>
                    <template v-if="product.rewardInfo.powerups.immortality.validUntil <= 24">
                      {{product.rewardInfo.powerups.immortality.validUntil}} Saat
                    </template>
                    <template v-else-if="product.rewardInfo.powerups.immortality.validUntil > 24">
                      {{product.rewardInfo.powerups.immortality.validUntil / 24}} Gün
                    </template>
                  </span>
                </div>
              </template>
              <template v-else>
                <img class="mb-2" :src="product.image" :alt="product.title">
                <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.rewind > 0">
                  <img src="@/assets/store/rewind.svg" class="mr-2" alt="">
                  <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.rewind }}</span>
                </div>
                <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.open > 0">
                  <img src="@/assets/store/open.svg" class="mr-2" alt="">
                  <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.open }}</span>
                </div>
                <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.change > 0">
                  <img src="@/assets/store/skip.svg" class="mr-2" alt="">
                  <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.change }}</span>
                </div>
                <div class="store-item__chest-item flex items-center" v-if="product.rewardInfo.powerups.hint > 0">
                  <img src="@/assets/store/hint.svg" class="mr-2" alt="">
                  <span class="text-stroke"><small>x</small>{{ product.rewardInfo.powerups.hint }}</span>
                </div>
              </template>
            </div>
            <div class="store-item__value">
              <span class="store-item__value-number">{{ product.price }}</span>
              <span class="store-item__value-currency">
                <template v-if="product.currency == 'TRY'">TL</template>
                <template v-else>
                  <img src="@/assets/coin-small.svg" alt="">
                </template>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal name="suggest-product-modal" classes="modal" :minWidth="200" :minHeight="400" :maxWidth="480"
           :scrollable="true"
           :adaptive="true" :reset="true" width="90%" height="auto">
      <button @click="$modal.hide('suggest-product-modal')" class="absolute right-0 mr-6 mt-6">
        <img src="@/assets/times-black.svg" alt="">
      </button>
      <div class="flex flex-col items-center">
        <h1 class="font-semibold text-center mt-8 flex" style="min-width:50%">Yetersiz Koin<img class="mx-1" src="@/assets/icon-coin.svg" alt="">!</h1>
        <p>Dilersen ihtiyacın olan Koini satın alabilirsin.</p>
        <template v-if="suggestedProduct">
          <div class="w-3/5 mt-2 mb-4 flex justify-center">
            <div class="store-item store-item--inactive justify-self-center">
              <div class="store-item__img"><img :src="suggestedProduct.image"
                                                alt="title"><span
                  class="text-stroke"><small>x</small>{{ suggestedProduct.rewardInfo.coins }}</span></div>
              <div class="store-item__value"><span class="store-item__value-number">{{
                  suggestedProduct.price
                }}</span><span
                  class="store-item__value-currency">TL</span></div>
            </div>
          </div>
          <button class="btn btn--primary self-center mb-5" @click="beginTransaction(suggestedProduct)">
            SATIN AL
          </button>
        </template>
      </div>

    </modal>



  </div>
</template>

<script>
import {mapActions, mapState} from "vuex"
import Feed from "@/mixins/Feed"
import moment from 'moment'

export default {
  mixins: [Feed],
  data() {
    return {
      errorMsg: null,
      activeTab: null,
      onlinePay: null,
      transactionId: null,
      fixedLeft: 0,
      suggestedProduct: null,
      storeAdRewards: {
        "ticket-packages": [],
        "coin-packages": []
      },
      adRewardsTimers: {
        "ticket-packages": {},
        "coin-packages": {}
      }
    }
  },
  props: {
    mode: {
      type: String,
      default: "page",
    },
    order: String,
  },
  computed: {
    ...mapState(["productGroups", "player", "adRewards"]),
    filteredProductGroups() {
      if(this.order && this.order == "ticket"){
        let productGroupsCopy = this._.cloneDeep(this.productGroups);
        const ticketProducts = productGroupsCopy.find(g => g.id == "2");
        productGroupsCopy.splice(productGroupsCopy.indexOf(ticketProducts), 1);
        productGroupsCopy.splice(0,0, ticketProducts);
        return productGroupsCopy;
      }
      return this.productGroups;
    }
  },
  created(){
    this.handleStoreAdRewards()

    if(this.$route.params.order=='offer'){
      this.beginTransaction(this.$route.params.data)
    }
  },
  mounted() {
    // Listen for messages
    window.addEventListener('message', (response) => {
      if (response.data && response.data.source == 'iframe' && response.data.message == 'close') {
        if (this.$refs.fortumoModal) {
          this.getPlayerInfo({id: this.player.id})
          this.$refs.fortumoModal.hide();
          this.getTransaction(this.transactionId).then((response) => {
            if (response.status == "c") {
              this.$gtag.purchase({
                "transaction_id": response.transactionId,
                "value": response.totalAmount
              })
              window.fbq('track', 'Purchase', {
                content_name: response.product.title,
                content_ids: [response.product.id],
                content_type: 'product',
                value: response.product.price,
                currency: response.product.currency
              });
              this.$modal.show("result-modal", {
                message : "Tebrikler, ödeme başarılı.",
                success : true,
                product : response.product
              });
            } else {
              this.$modal.show("result-modal", {
                message : "Ödeme sırasında bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz.",
                success : false
              });
            }
          }).catch(function () {
            this.$modal.show("result-modal", {
              message : "Ödeme sırasında bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz.",
              success : false
            });
          })
        }
      }
    });
    this.retrieveProductGroups().then(() => {
      this.$nextTick(() => {
        this.checkActiveTab();
        if (this.mode == 'modal') {
          document.getElementById("store-content").addEventListener("scroll", this.checkActiveTab)
        } else {
          window.addEventListener("scroll", this.checkActiveTab)
        }
      })
    });

    this.setTabLeftPosition();
    window.addEventListener("resize", this.setTabLeftPosition)
  },
  beforeDestroy() {
    if (this.mode == 'modal') {
      document.getElementById("store-content").removeEventListener("scroll", this.checkActiveTab)
    } else {
      window.removeEventListener("scroll", this.checkActiveTab);
    }
    window.removeEventListener("resize", this.setTabLeftPosition)
  },
  watch: {
    "activeTab": {
      handler(val) {
        var param = null
        switch(val) {
          case "tab2":
            param = 'ticket_packs';
            break;
          case "tab3":
            param = 'powerup_packs';
            break;
          case "tab4":
            param = 'combo_packs';
            break;
          case "tab1":
            default:
              param = 'coin_packs';
              break;
        }

        this.feedAllEvent('view_store_segment', null, 'engagement', param, param)
      }
    }
  },
  methods: {
    ...mapActions(["buyCoins", "buyTickets", "purchaseStart", "purchaseProduct", "retrieveProductGroups", "getPlayerInfo", "getTransaction", "getAdRewardsForLocation", "sendRewardForAd"]),
    setTabLeftPosition() {
      const layoutMain = document.getElementsByClassName('layout-main')[0];
      this.fixedLeft = layoutMain.offsetLeft;
    },
    checkActiveTab() {
      let section2 = document.getElementById("section-2").offsetTop - 40;
      let section3 = document.getElementById("section-3").offsetTop - 40;
      let section4 = document.getElementById("section-4").offsetTop - 40
      let windowTop = window.top.scrollY + 115
      if (this.mode == 'modal') {
        windowTop = document.getElementById("store-content").scrollTop + 115;
      }

      if (0 < windowTop && windowTop < section2) {
        this.activeTab = 'tab1'
      } else if (section2 <= windowTop && windowTop < section3) {
        this.activeTab = 'tab2'
      } else if (section3 <= windowTop && windowTop < section4) {
        this.activeTab = 'tab3'
      } else if (section4 <= windowTop) {
        this.activeTab = 'tab4'
      }
    },
    onClickStoreTab(sectionId) {
      let section = document.getElementById(sectionId).offsetTop - 40 - 115;
      if (this.mode == 'modal') {
        document.getElementById("store-content").scrollTo(0, section);
      } else {
        window.scrollTo(0, section);
      }
    },
    beginTransaction(product) {
      if (product.type == 'c' || product.type == 'r') {
        this.purchaseStart({
          qty: 1,
          productId: product.id
        }).then((response) => {
          this.$modal.hide("suggest-product-modal")
          this.$gtag.event('begin_checkout', {
            'currency': 'TRY',
            'checkout_step': 1,
            'value': response.data.result.set.totalAmount,
            'items': [
              {
                'id': response.data.result.set.product.sku,
                'name': response.data.result.set.product.title,
                'price': response.data.result.set.product.price,
                'quantity': response.data.result.set.quantity
              }
            ]

          })
          /*
          window.fbq('track', 'InitiateCheckout', {
            content_name: response.data.result.set.product.title,
            content_ids: [response.data.result.set.product.id],
            content_type: 'product',
            value: response.data.result.set.product.price,
            currency: response.data.result.set.product.currency
          });*/

          this.transactionId = response.data.result.set.transactionId;
          let paymentPayload = {
            transactionId: this.transactionId,
            product: response.data.result.set.product,
            productTL: response.data.result.set.totalAmount,
            player: this.player,
            paymentMethods: response.data.result.set.availablePaymentMethods
          }
          this.$modal.show("payment-method-modal", paymentPayload)
        });
        
      } else {
        this.errorMsg = null
        if (this.player.coins < product.price) {
          /*this.$modal.show("result-modal", {
            message : "Üzgünüz, yetersiz bakiye.",
            success : false
          });*/
          this.productGroups.forEach((group) => {
            if(group.title.indexOf('KOİN') > -1){
              let stop = 0
              group.products.forEach((prod) => {
                if(prod.rewardInfo.coins + this.player.coins > product.price && stop == 0){
                  this.$modal.show("suggest-product-modal")
                  this.suggestedProduct = prod
                  stop = 1
                }
              })
            }
          });
        } else {
          this.purchaseProduct({id: product.id}).then(() => {
            this.$modal.show("result-modal", {
              message : "Tebrikler, ödeme başarılı.",
              success : true,
              product: product
            });

            this.feedAllEvent('purchase_product', product.price, 'ecommerce', product.sku, product.sku)
            this.feedAllEvent('coins_spent', product.price, 'ecommerce', this.mode == 'modal' ? 'in-game' : 'store', product.sku)
            if(this.mode == 'modal') {
              var productType = null
              switch(product.type) {
                case 't':
                  productType = 'tip'
                  break
                case 'o':
                  productType = 'open'
                  break
                case 'p':
                  productType = 'powerup'
                  break
                default:
                  productType = null
                  break
              }

              if(null != productType) {
                this.feedAllEvent('powerup_purchase', null, 'ecommerce', productType, productType)
              }
            }

            this.$emit('purchase', true)
          }).catch(() => {
            this.$emit('purchase', false)
            this.$modal.show("result-modal", {
              message : "Ödeme sırasında bir sorun oluştu. Lütfen daha sonra tekrar deneyiniz.",
              success : false
            });
          })
        }
      }
    },
    handleStoreAdRewards() {
      this.getAdRewardsForLocation('store').then(() => {
        this.prepareStoreAdRewards()
      })
    },
    prepareStoreAdRewards() {
      if(this.adRewards.store) {
        this._.map(this.adRewards.store, adReward => {
          let position = adReward.position
          this.storeAdRewards[position] = []
          this.storeAdRewards[position].push(adReward)
          if(null != adReward.availableAt) {
            this.adRewardTimerForPosition(adReward.id, position, adReward.availableAt)
          }
        })

      }
    },
    adRewardTimerForPosition(adRewardId, position, availableDate) {
      if(this.adRewardsTimers[position][adRewardId] && null != this.adRewardsTimers[position][adRewardId].timer) clearInterval(this.adRewardsTimers[position][adRewardId].timer)

      this.$set(this.adRewardsTimers[position], adRewardId, {timer: null, text: null})

      let availableAt = moment(availableDate)
      
      this.adRewardsTimers[position][adRewardId].timer = setInterval(() => {
        let now = moment()
        let duration = moment.duration(availableAt.diff(now))

        let hours = duration.hours()
        if(hours < 10) {
          hours = "0" + hours
        }

        let minutes = duration.minutes()
        if(minutes < 10) {
          minutes = "0" + minutes
        }
        
        let seconds = duration.seconds()
        if(seconds < 10) {
          seconds = "0" + seconds
        }

        var hourText = ''
        if(hours > 0) {
          hourText += hours + ':'
        }

        this.setTimerText(position, adRewardId, hourText + minutes + ":" + seconds)

        if (hours == 0 && minutes == "00" && seconds == "00") {
            if(this.adRewardsTimers[position][adRewardId].timer) clearInterval(this.adRewardsTimers[position][adRewardId].timer);
            this.handleStoreAdRewards()
        }
      }, 1000);
    },
    onClickRewardAdButton(adPlacementCode, rewardType) {
      if (typeof window.aiptag.rewardAdplayer !== 'undefined') {
        if(Math.floor(Math.random() * 100) + 1 <= 50) {
          this.showAdinRewardAd(adPlacementCode, rewardType);
        }
        else {
          this.showGoogleRewardAd(adPlacementCode, rewardType);
        }
      }
      else {
        this.showGoogleRewardAd(adPlacementCode, rewardType);
      }
    },
    showAdinRewardAd(adPlacementCode, rewardType) {
      window.aiptag.rewardAdplayer.aipConfig.AIP_REWARDEDCOMPLETE = (evt) => {
        //evt can be: timeout, empty or closed
        
        console.log("Rewarded Ad Completed (In Game): " + evt);

        if(evt == "empty" || evt == "timeout" || evt == "notsetup") {
          this.showGoogleRewardAd(adPlacementCode, rewardType);
        }

      };
      window.aiptag.rewardAdplayer.aipConfig.AIP_REWARDEDGRANTED = () =>  {
        console.log("Reward Granted (In Game)");
        this.handleRewardCallback("adi", adPlacementCode, rewardType);
      };

      window.aiptag.cmd.player.push(function() { window.aiptag.rewardAdplayer.startRewardedAd(); });
    },
    showGoogleRewardAd(adPlacementCode, rewardType) {
      window.adBreak({
        type: 'reward',  // ad shows at start of next level
        name: 'google-reward',
          beforeReward: (showAdFn) => {
            showAdFn();
          },
          adDismissed: () => {},
          adViewed: () => {
            this.handleRewardCallback("adi", adPlacementCode, rewardType);
          }
      });
    },
    setTimerText(position, adRewardId, text) {
      this.$set(this.adRewardsTimers[position][adRewardId], 'text', text)
    },
    handleRewardCallback(networkCode, adPlacementCode, rewardType) {
      var payload = {
        networkCode: networkCode,
        data: {
          gameplayId: this.gameId
        },
        adPlacementCode
      }
      
      var eventName = null
      switch(rewardType) {
        case 'coin':
          eventName = 'view_promo_coin_stat'
          break;
        case 'ticket':
          eventName = 'view_promo_ticket_stat'
          break;
        default:
          eventName = null
          break;
      }

      if(null != eventName) {
        this.feedAllEvent(eventName, null, 'engagement', networkCode, networkCode)
      }

      this.sendRewardForAd(payload).then(() => {
        this.handleStoreAdRewards()
      });
    }
  }
}
</script>