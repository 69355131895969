<template>
  <div>
    <modal
        name="login-and-register-modal"
        classes="modal"
        :minWidth="200" 
        :minHeight="400" 
        :maxWidth="480" 
        :scrollable="true"
        :reset="true"
        :height="'auto'"
        :adaptive="true" 
        width="90%"
        @closed="onCloseModal"
        >
        
      <div class="flex justify-end relative">
        <button @click="$modal.hide('login-and-register-modal')" class="absolute right-0 mr-4 mt-4">
          <img src="@/assets/times-black.svg" alt="">
        </button>
      </div>
      
      <h2 class="text-center my-4" style="font-size: 24px">{{'login' == type ? "GİRİŞ YAP" : "ÜYE OL"}} <br> OYNAMAYA BAŞLA</h2>
      
        <div class="mx-auto flex flex-col items-center px-4">
          <p class="mb-4 text-center text-danger" v-html="this.errorMsg"></p>
          <form class="flex flex-col items-center w-full" @submit.prevent="registerAction">
            <validation-observer class="flex flex-col" style="width:80%" ref="form">
              <validation-provider class="mb-4" tag="div" name="E-Mail Adresi" rules="required|email" v-slot="{errors}">
                <input type="text" :disabled="loading" v-model="loginData.username" required name="username" class="form-special" placeholder="E-Mail Adresi">
                <div class="error-label">{{ errors[0] }}</div>
              </validation-provider>  
              <validation-provider tag="div" name="Şifre" rules="required" v-slot="{errors}">
                <input type="password" :disabled="loading" v-model="loginData.password" required name="password" class="form-special" placeholder="Parola">
                <div class="error-label">{{ errors[0] }}</div>
              </validation-provider>
              <div class="text-right mb-4">
                <button type="button" class="mb-3 underline" v-if="'login' == type" @click="clickResetPassword()">Parolamı unuttum</button>
              </div>
              <validation-provider v-if="type == 'register'" tag="div" name="Gizlilik Politikası ve KVKK Aydınlatma Metni" rules="required" v-slot="{errors}">
                <div class=" mb-4">
                  <label for="kvkk"><input id="kvkk" type="checkbox" v-model="kvkk" :true-value="true" :false-value="null" required name="kvkk" class="mr-1"> <a href="/privacy" class="underline" target="_blank">Gizlilik Politikası ve KVKK Aydınlatma Metnini</a> okudum onaylıyorum.</label>
                  <div class="error-label">{{ errors[0] }}</div>
                </div>
              </validation-provider>
              <button type="submit" class="btn btn--primary block mb-5 mx-auto" v-bind:class="{'ld-over-inverse running' : this.loading == true}" v-if="type == 'login'" @click.prevent="loginAction">
                Giriş Yap
                <div class="ld ld-ring ld-spin" v-bind:class="{'running' : this.loading == true}" v-if="loading"></div>
              </button>

              <button type="submit" class="btn btn--secondary block mb-5 mx-auto" v-else v-bind:class="{'ld-over-inverse running' : this.loading == true}" @click.prevent="registerAction">
                Üye Ol 
                <div class="ld ld-ring ld-spin" v-bind:class="{'running' : this.loading == true}" v-if="this.loading"></div>
              </button>
            </validation-observer>
          </form>

          <button type="button" class="mb-3 underline" v-if="'login' == type"  @click="changeLoginType('register')">Henüz üye değilim</button>
          <button type="button" class="mb-3 underline" @click="changeLoginType('login')" v-else>Zaten üyeyim</button>
        </div>
        

    </modal>

    <reset-password-modal :username="_.cloneDeep(loginData.username)" @handleReset="onRequestPasswordReset"></reset-password-modal>

    <verification-modal 
        ref="password-reset-modal" 
        name="password-reset-modal" 
        type="password" 
        :username="this.loginData.username"
        title="Parolanı Sıfırla" 
        description="Size iletilen kodu girin"
      />

  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import ResetPasswordModal from './ResetPasswordModal';
import VerificationModal from "@/views/components/VerificationModal"
import { ValidationProvider, ValidationObserver } from 'vee-validate';

export default {
  components: {ResetPasswordModal, VerificationModal, ValidationProvider, ValidationObserver},
  name: "login-and-register-modal",
  data() {
    return {
      type: 'login',
      loginData: {
        username: null,
        password: null
      },
      kvkk: null,
      errorMsg: "",
      loading: false
    }
  },
  computed: {
    ...mapState(["player"]),
  },
  methods: {
    ...mapActions(['login', 'register']),
    loginAction() {
      this.errorMsg = "";
      this.$refs.form.validate().then((isValid) => {
        if(!isValid) return;
        this.loading = true;
        this.login(this.loginData).then(response => {
          this.loading = false;
          this.$gtag.event('login', {userId: response.data.result.set.id, source: "loginEmail"});
          this.$OneSignal.setExternalUserId(this.player.id)
          this.$modal.hide("login-and-register-modal");
        }).catch(error => {
          this.loading = false;
          this.$toast.error(error.response.data.message);
        });
      })

    },
    registerAction() {
      this.errorMsg = "";
      this.$refs.form.validate().then((isValid) => {
        if(!isValid) return;
        this.loading = true;
        this.register(this.loginData).then(() => {
          this.loading = false;
          this.openVerificationModal()
          this.$toast.success('Kayıt işlemi Başarıyla gerçekleşti, giriş yapabilirsiniz');
        }).catch(error => {
          this.errorMsg = error.response.data.message
          this.$toast.error(error.response.data.message);
          this.loading = false;
        });
      })
      
    },
    onRequestPasswordReset(e) {
      this.loginData.username = e.username
      this.$modal.hide('reset-password-modal')
      this.$modal.show('password-reset-modal')
    },
    openVerificationModal() {
      this.$modal.hide("login-and-register-modal");
      this.$modal.show("otp-verification-modal");
    },
    clickResetPassword() {
      this.$modal.show('reset-password-modal')
      this.$modal.hide("login-and-register-modal")
    },
    changeLoginType(type) {
      this.$refs.form.reset();
      this.type = type
    },
    changeScreen(screen) {
      this.currentScreen = screen
    },
    onCloseModal() {
      this.loginData = {
        username: null,
        password: null
      };
    }
  }
}
</script>

<style scoped>

</style>