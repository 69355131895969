<template>
  <div class="layout-main vld-parent">
  <full-screen-button></full-screen-button>
  <v-dialog :width="600" />
    <div class="nav-wrapper">
      <header class="header flex flex-col justify-center items-center">
        <div style="width:100%" class="relative z-10">
          <div id='adam-asmaca-com_320x50' class="flex justify-center"></div>
        </div>
        <div class="container mx-auto max-w-sm px-4 items-center"
          :class="[this.$store.state.authenticated ? 'grid grid-cols-3 gap-4' : 'grid grid-cols-3 gap-4' ]">
          <div class="menu" @click="onClickMenu">
            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path class="line"
                d="M10.7738 17.5925C18.0603 17.5925 27.9454 15.9485 34.8294 18.0904C40.3027 19.7934 45.5682 17.5925 50.7738 17.5925"
                stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
              <path class="line" d="M10.7738 30.1761C19.4824 30.1761 27.548 29.1805 36.2695 29.1805" stroke="black"
                stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
              <path class="line"
                d="M10.0382 41.8011C14.3513 41.7411 18.5165 41.0543 22.8647 41.0543C26.963 41.0543 31.2498 40.8058 35.3014 41.2479C38.5288 41.6002 42.048 41.3033 45.3209 41.3033C46.2827 41.3033 49.6176 41.4022 50.0382 40.8054"
                stroke="black" stroke-width="5" stroke-linecap="round" stroke-linejoin="round" />
            </svg>
          </div>
          <div class="logo" :class="[this.$store.state.authenticated ? 'flex items-center' : '' ]" @click="onClickHome">
            <router-link :to="{name: 'dashboard', query: this.$route.query}"><img :src="require('@/assets/logo.svg')"
                alt="" :class="[this.$store.state.authenticated ? '' : 'mx-auto']">
            </router-link>
          </div>
          <currencies v-if="this.$store.state.authenticated"></currencies>
        </div>
      </header>
      <div class="menu-content pt-8">
        <div class="container mx-auto max-w-sm">
          <nav class="flex flex-col">
            <router-link :to="{name: 'dashboard', query: this.$route.query}" class="self-start">ANASAYFA</router-link>
            <a href="/rehber">NASIL OYNANIR?</a>
            <a href="/rehber/oduller">ÖDÜLLER</a>
            <router-link :to="{name: 'help', query: this.$route.query}" class="self-start">YARDIM</router-link>
            <router-link :to="{name: 'tos', query: this.$route.query}" class="nav-small nav-small-first self-start">KULLANIM ŞARTLARI</router-link>
            <router-link :to="{name: 'privacy', query: this.$route.query}" class="nav-small self-start">GİZLİLİK POLİTİKASI VE KVKK</router-link>
          </nav>
        </div>
        <div class="container mx-auto max-w-sm mb-8">
          <navigation-bottom class="mt-8"></navigation-bottom>
        </div>
        <div class="px-4 py-2 my-2 flex justify-center w-full" style="bottom: 60px; z-index: 100">
          <button class="btn btn--primary btn--sm mr-8 ml-8" style="min-width: 200px" @click.prevent="onClickCreateGame">OYNA ÖDÜL KAZAN</button>
        </div>
      </div>
    </div>
    <div class="main mx-auto">
      <transition name="fade" mode="out-in">
        <router-view />
      </transition>  
    </div>
    <promo-modal ref="promoModal" @rewardGranted="onHandleRewardGranted"></promo-modal>
  </div>
</template>
<script>
  import Currencies from "@/views/components/Currencies"
  import NavigationBottom from "@/views/components/NavigationBottom"
  import FullScreenButton from "@/views/components/FullScreenButton";
  import { mapActions, mapGetters } from 'vuex';
  import Feed from "@/mixins/Feed"
  import PromoModal from '@/views/components/PromoModal'

  export default {
    components: {
      FullScreenButton,
      Currencies,
      NavigationBottom,
      PromoModal
    },
    data() {
      return {
        adSlot: null
      }
    },
    mixins: [Feed],
    watch: {
      $route() {
        this.closeMenu();
      }
    },
    beforeDestroy() {
      document.removeEventListener("scroll", this.onScroll);
    },
    mounted() {
        window.aiptag.cmd.display.push(function() { window.aipDisplayTag.display('adam-asmaca-com_320x50'); });
    },
    methods: {
      ...mapActions(["feedHourlyStat", "createGame", "checkOpenGame"]),
      ...mapGetters(["checkTicket", "hasImmortality"]),
      onClickCreateGame() {
        this.checkOpenGame().then(() => {
          if (this.checkTicket()) {
            this.createGame({gameType: '1'}).then(() => {
              this.$emit('onCreate')
              this.$router.push({name: "game", params: {id: 'lig-oyunu'}, query: this.$route.query})
            })
          } else {
            this.$modal.show("promo-modal", {promoType: "ticket"});
          }
        });
      },
      onClickMenu() {
        this.feedAllEvent('click_button', null, 'interaction', 'menu', 'menu')
        var element = document.querySelector(".nav-wrapper");
        element.classList.toggle("active");
        document.body.classList.toggle("overflow-hidden");
        if(element.classList.contains("active")) {
          this.feedAllEvent('view_menu', null, 'engagement')
        }
      },
      closeMenu() {
        setTimeout(() => {
          var element = document.querySelector(".nav-wrapper");
          element.classList.remove("active");
          document.body.classList.remove("overflow-hidden");
        }, 300)
      },
      onScroll() {
        if (window.pageYOffset > 0) {
          document.querySelector(".nav-wrapper").classList.add("scrolled")
        } else {
          document.querySelector(".nav-wrapper").classList.remove("scrolled")
        }
      },
      onClickHome() {
        this.feedAllEvent('click_button', null, 'interaction', 'home', 'home')
      },
      onHandleRewardGranted(promoType){
        if(promoType == "ticket") {
          this.checkOpenGame().then(() => {
            this.createGame({gameType: '1'}).then(() => {
              this.$router.push({name: "game", params: {id: 'lig-oyunu'}, query: this.$route.query})
            })
          });
        }
      },
    }
  }
</script>