<template>
  <div>
    <modal
        name="payment-method-modal"
        classes="modal"
        :minWidth="200"
        :minHeight="400"
        :maxWidth="480"
        :scrollable="true"
        :adaptive="true"
        :reset="true"
        width="100%"
        @before-open="onBeforeOpen"
        @closed="handleClose"
        height="auto">
      <div class="flex items-center">
        <button class="btn-tab" v-bind:class="{'active' : this.componentName === 'StripeComponent'}" @click="openStripe">Kredi Kartı</button>
      </div>
      <div class="flex items-center justify-between px-8 py-4 border-b-2 border-dashed border-gray-400" v-if="product">
        <div class="flex item-center">
          <img :src="product.image" :alt="product.title" style="min-height: 60px">
          <div class="flex flex-col ml-2">
            <template v-if="product.type == 'c' && product.rewardInfo.coins > 0">
              <div class="text-2xl font-semibold">{{setDotInNumber(product.rewardInfo.coins)}}</div>
              <div class="text-sm">KOİN PAKETİ</div>
            </template>
            <template v-if="product.type == 'r' && product.rewardInfo.coins > 0">
              <div class="text-2xl font-semibold">{{setDotInNumber(product.rewardInfo.coins)}}</div>
              <div class="text-sm">KOİN PAKETİ</div>
            </template>
            <template v-if="product.type == 'r' && product.rewardInfo.powerups.change > 0">
              <div class="text-2xl font-semibold">{{setDotInNumber(product.rewardInfo.powerups.change)}}x</div>
              <div class="text-sm">PAS GÜCÜ</div>
            </template>
            <template v-if="product.type == 'r' && product.rewardInfo.powerups.open > 0">
              <div class="text-2xl font-semibold">{{setDotInNumber(product.rewardInfo.powerups.open)}}x</div>
              <div class="text-sm">HARF AÇMA GÜCÜ</div>
            </template>
            <template v-if="product.type == 'r' && product.rewardInfo.powerups.rewind > 0">
              <div class="text-2xl font-semibold">{{setDotInNumber(product.rewardInfo.powerups.rewind)}}x</div>
              <div class="text-sm">GERİ ALMA GÜCÜ</div>
            </template>
            <template v-if="product.type == 'r' && product.rewardInfo.powerups.hint > 0">
              <div class="text-2xl font-semibold">{{setDotInNumber(product.rewardInfo.powerups.hint)}}x</div>
              <div class="text-sm">İPUCU GÜCÜ</div>
            </template>
            <template v-if="product.type == 'r' && product.rewardInfo.powerups.ticket > 0">
              <div class="text-2xl font-semibold">{{setDotInNumber(product.rewardInfo.powerups.ticket)}}x</div>
              <div class="text-sm">BİLET</div>
            </template>
            <template v-if="product.type == 'r' && product.rewardInfo.powerups.immortality.validUntil > 0">
              <div class="text-2xl font-semibold"><small>+</small> {{product.rewardInfo.powerups.immortality.validUntil}} Saat</div>
              <div class="text-sm">BİLET</div>
            </template>
          </div>
        </div>
        <div class="flex flex-col items-end">
          <div class="text-2xl font-semibold">{{productTL}}<span class="font-normal text-base ml-1">₺</span></div>
          <div class="text-sm">(<span class="mr-1">{{product.price}}</span>{{productCurrency}})</div>
        </div>
      </div>
      <component :is="componentName" v-bind="this.props" @onCallback="(data) => onHandleCallback(data)"></component>
      <div class="flex items-center" style="font-size: 10px; margin:0 10px auto">
        * Ödeyeceğiniz tutar kullanmış olduğunuz kredi kartı ve bankanızın döviz politikaları kaynaklı değişkenlik gösterebilmektedir.
      </div>
    </modal>
  </div>
</template>

<script>
import FortumoComponent from "@/views/components/PaymentComponent/FortumoComponent";
import StripeComponent from "@/views/components/PaymentComponent/StripeComponent";

export default {
  name: "PaymentMethod",
  components: {FortumoComponent, StripeComponent},
  data() {
    return {
      transactionId: null,
      product: null,
      productCurrency: null,
      productTL: null,
      player: null,
      componentName: "StripeComponent",
      paymentMethods: []
    }
  },
  computed: {
    props() {
      return {
        transactionId: this.transactionId,
        product: this.product,
        player: this.player,
        url: this.transactionId && this.player.id && this.product.sku ? '524664b9787712ebe88deefa492491dd?operation_reference=' + this.transactionId + '&cuid=' + this.player.id + '&tc_id=' + this.product.sku : null,
        availablePaymentMethods: this.paymentMethods
      }
    },
  },
  watch: {
    'product': function () {
      this.componentName = 'StripeComponent';
    }
  },
  created() {
    this.openStripe();
  },
  methods: {
    onHandleCallback(data) {
      this.$modal.show("result-modal", data);
      this.hide();
      this.$emit('onCallback', data)
    },
    onBeforeOpen(event){
      const payload = event.params;
      if(payload) {
        this.transactionId = payload.transactionId;
        this.product = payload.product;
        this.player = payload.player;
        this.paymentMethods = payload.paymentMethods;
        if(payload.product.currency == 'EUR'){ this.productCurrency = '€' }
        if(payload.product.currency == 'USD'){ this.productCurrency = '$' }
        if(payload.product.currency == 'TL'){ this.productCurrency = '₺' }
        this.productTL = payload.productTL;
      }
    },
    setDotInNumber(value) {
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    },
    handleClose(){
      this.componentName = 'StripeComponent';
      if(this.$route.name == "game" && this.gameStatus != null) {
        this.$modal.show("game-result-modal")
      }
    },
    show() {
      this.$modal.show("payment-method-modal");
    },
    hide() {
      this.$modal.hide("payment-method-modal");
    },
    openStripe() {
      this.componentName = 'StripeComponent';
    },
    openFortumo() {
        this.componentName = 'FortumoComponent'
    }
  }
}
</script>

<style scoped>

</style>