<template>
  <div id="app">
    <transition name="scale" mode="out-in">
      <router-view/>
    </transition>
    <store-modal ref="storeModal"></store-modal>
    <help-modal ref="helpModal"></help-modal>
    <network-status-modal ref="networkStatusModal"></network-status-modal>
    <reward-modal></reward-modal>
    <login-modal ref="loginModal"></login-modal>
    <login-and-register-modal></login-and-register-modal>
    <error-modal ref="errorModal"></error-modal>
    <notification-modal></notification-modal>
    <payment-method></payment-method>
    <result-modal></result-modal>

    <verification-modal 
      ref="otp-verification-modal" 
      name="otp-verification-modal" 
      type="register" 
      title="Hesabımı Doğrula" 
      description="Size iletilen kodu girin"
      />
  </div>
</template>
<script>

import StoreModal from "@/views/components/StoreModal";
// const HelpModal = import("@/views/components/HelpModal");
import NetworkStatusModal from "@/views/components/NetworkStatusModal";
import LoginModal from '@/views/components/LoginModal'
import {mapActions} from "vuex";
import UID from "uniquebrowserid";
import Feed from "@/mixins/Feed"
import RewardModal from '@/views/components/RewardModal.vue';
import ErrorModal from '@/views/components/ErrorModal.vue';
import VerificationModal from "@/views/components/VerificationModal";
import LoginAndRegisterModal from '@/views/components/LoginAndRegisterModal'
import NotificationModal from '@/views/components/NotificationModal'
import PaymentMethod from '@/views/components/PaymentMethod'
import ResultModal from '@/views/components/ResultModal'

export default {
  components: {
    NetworkStatusModal, 
    StoreModal, 
    HelpModal : () => import("@/views/components/HelpModal"), 
    RewardModal, LoginModal, ErrorModal, VerificationModal, LoginAndRegisterModal, NotificationModal, PaymentMethod, ResultModal},
  mixins: [Feed],
  data(){
    return {
      networkStatus : navigator.onLine
    }
  },
  created() {
    let queries = this.$route.query;
    if(queries.gclid){
      this.$cookies.set('origin-campaign-id', queries.gclid);
    }
    if(queries.fbclid){
      this.$cookies.set('origin-campaign-id', queries.fbclid);
    }
    if(queries.ref_id){
      this.$cookies.set('fb-share-return-id', queries.ref_id);
    }
    if(queries.scode){
      this.$cookies.set('invited-user', queries.scode);
    }

    if(this.$store.state.authenticated)
    {
      this.$gtag.config({userId: localStorage.getItem('playerId'), user_id: localStorage.getItem('playerId')});
      this.$cookies.set('aa_userId', localStorage.getItem('playerId'));
    }else{
      var deviceId = localStorage.getItem('aa_deviceId');

      if(!deviceId || typeof(deviceId) == "undefined"){
        deviceId = new UID().completeID();
        localStorage.setItem('aa_deviceId', deviceId);
      }

      let cookie = this.$cookies.get("origin-campaign-id")
      let payload = {deviceId: deviceId}
      if(cookie){ payload.originCampaignId = cookie }
      this.guestLogin(payload)
    }

    window.ononline = (event) => {
      console.log(event,"You are now connected to the network.");
    };

    window.addEventListener('online', this.updateConnectionStatus);
    window.addEventListener('offline', this.updateConnectionStatus);

    if (typeof (window.FBInstant) != 'undefined') {
      window.FBInstant.setLoadingProgress(100);
      window.FBInstant.startGameAsync()
          .then(function () {
          })
    }

    let _this = this
        // Ask for categories which are player will accept
    _this.$OneSignal.on('subscriptionChange', function(isSubscribed) {
      if(isSubscribed) {
        _this.$OneSignal.showCategorySlidedown()

        let checkTags = _this.$OneSignal.getTags().then((tags) => {
              _this.feedMissingOneSignalTags(tags)
            })

        let checkUserId = _this.$OneSignal.getUserId().then((osUserId) => {
              if(osUserId) {
                _this.$store.dispatch('updateOSUserId', {userId: osUserId});
              }
            })

        Promise.all([checkTags, checkUserId])
      }
    })



  },
  methods: {
    ...mapActions(["guestLogin", "getPlayerNotifications"]),
    updateConnectionStatus() {
     this.networkStatus = navigator.onLine
    }
  },
  watch:{
    networkStatus: function (val) {
      if(val === false) {
        this.$refs.networkStatusModal.show();
      }else if(val === true){
        this.$refs.networkStatusModal.hide()
      }
    }
  },
  metaInfo: {
    title: 'Anasayfa',
    titleTemplate: '%s | Adam Asmaca Oyunu',
    // meta: [
    //   {property: 'description', content:"En eğlenceli Türkçe Adam Asmaca oyunu. Haftalık ligler, çevrimiçi oyun modu, tarayıcı üzerinden oynanabilir HTML5 oyun motoru, oyun içi güçler, Facebook hesabı ile oynama, Google Hesabı ile oynama ve daha fazlasını sunan Türkiye'nin en iyi Adam Asmaca Oyunu. - Kelime oyunları ustası Raviosoft tarafından hazırlanmıştır."},
    //   {property: 'og:title', content: 'Adam Asmaca Oyunu'},
    //   {property: 'og:type', content: 'game'},
    //   {property: 'og:url', content: 'https://www.adam-asmaca.com'},
    //   {property: 'og:description', content:"En eğlenceli Türkçe Adam Asmaca oyunu. Haftalık ligler, çevrimiçi oyun modu, tarayıcı üzerinden oynanabilir HTML5 oyun motoru, oyun içi güçler, Facebook hesabı ile oynama, Google Hesabı ile oynama ve daha fazlasını sunan Türkiye'nin en iyi Adam Asmaca Oyunu. - Kelime oyunları ustası Raviosoft tarafından hazırlanmıştır."},
    //   {property: 'og:site_name', content:'adam-asmaca.com'},
    //   {httpEquiv: 'Content-Security-Policy', content: 'frame-src *;'},
    //   {httpEquiv: 'Content-Type', content: 'text/html; charset=UTF-8'},
    //   {property: 'sg:type', content: 'portal'},
    //   {name: 'viewport', content: 'width=device-width, initial-scale=1'},
    //   {name: 'apple-mobile-web-app-title', content:'adam-asmaca.com'},
    //   {name: 'application-name', content:'adam-asmaca.com'}
    // ]
  }
}
</script>