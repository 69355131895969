import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueGtag from 'vue-gtag'
import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
//import * as Sentry from "@sentry/vue";
//import { BrowserTracing } from "@sentry/tracing";
import OneSignalVue from 'onesignal-vue'
Vue.use(OneSignalVue)
if(typeof navigator.serviceWorker !== "undefined"){ navigator.serviceWorker.register('/OneSignalSDKWorker.js') }

import VueToggles from 'vue-toggles';

Vue.component('VueToggles', VueToggles);

const UID = require('uniquebrowserid').default;
Vue.prototype.$uid =  new UID().completeID();

import VueMeta from 'vue-meta'
Vue.use(VueMeta)

// Import component
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading)
Vue.component('loading', Loading)

// name is optional
Vue.use(VueLodash, {name: 'custom', lodash: lodash})

import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast);

// Vue Modal
import VModal from 'vue-js-modal'

Vue.use(VModal, {dialog: true});

// Vue Infinite Scroll
import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)


// Tailwind
import '@/assets/css/main.css'
import './assets/scss/main.scss'

Vue.config.productionTip = false

import googleAuth from "./config/googleAuth";
import facebookAuth from "./config/facebookAuth";

const gauthOption = {
    clientId: process.env.VUE_APP_GOOGLE_CLIENT_ID,
    scope: 'profile email',
    prompt: 'select_account'
}
const fAuthOption = {
    appId: process.env.VUE_APP_FACEBOOK_APP_ID, //You will need to change this
    cookie: true, // This is important, it's not enabled by default
    version: "v11.0"
}

Vue.use(googleAuth, gauthOption)
Vue.use(facebookAuth, fAuthOption);

if (window.FBInstant) {
    window.FBInstant.initializeAsync();
}

initVue()

import { extend, localize } from 'vee-validate';
import { required, email, alpha, confirmed } from 'vee-validate/dist/rules';
import tr from 'vee-validate/dist/locale/tr.json';

// No message specified.
extend('email', email);
extend('required', required);
extend('alpha', alpha);
extend('confirmed', confirmed);

localize('tr', tr);

const firebaseConfig = {
    apiKey: "AIzaSyCZ8Douk7AGKYyWB9NeHj3qwjylIrrB6-c",
    authDomain: "adam-asmaca-324011.firebaseapp.com",
    projectId: "adam-asmaca-324011",
    storageBucket: "adam-asmaca-324011.appspot.com",
    messagingSenderId: "858780313433",
    appId: "1:858780313433:web:f4fdb7c12317a5bac50e48",
    measurementId: "G-XMPPZ2H96E"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

import VueCookies from 'vue-cookies'
Vue.use(VueCookies);
Vue.$cookies.config('90d');

let configGtag = {
    config: {id: process.env.VUE_APP_GOOGLE_UA_ID},
    enabled : false,
    includes: [
        {
            id: 'G-VLLLVGWVMV'
        },
        {
            id: 'AW-568560635'
        }
    ],
}

let cookie = document.cookie
if((cookie.indexOf("CookieScriptConsent")>-1 && Vue.$cookies.get("CookieScriptConsent").categories.indexOf('performance')>0) || cookie.indexOf('CookieScriptConsent={"action":"reject"')<0){
    Vue.prototype.$analytics = getAnalytics(app);
    configGtag.enabled = true
}

Vue.use(VueGtag, configGtag, router);
/*
Sentry.init({
    Vue,
});

Sentry.init({
    Vue,
    dsn: "https://2f97e86d5d7646f2abaa2281206389dd@o335526.ingest.sentry.io/5903793",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracePropagationTargets: ["localhost", "adam-asmaca.com", /^\//],
        }),
    ],
    tracesSampleRate: 50.0,
    maxBreadcrumbs: 10,
    ignoreErrors: ['ResizeObserver loop limit exceeded']
});*/

function initVue() {
    store.dispatch('init').then(() => {
        new Vue({
            router,
            store,
            render: h => h(App)
        }).$mount('#app')
    })
}